import { css } from 'goober'
import KeenSlider, { KeenSliderInstance, KeenSliderHooks } from 'keen-slider'
import { RiMapMap2Fill } from 'solid-icons/ri'
import { createEffect, createSignal, For, Suspense } from 'solid-js'
import { Div, Section, Button, Span } from '~/components/html-living-standard'
import { Header, Item, Footer } from '~/components/root/list'
import { size, position, merge, font, shape } from '~/utils/css-shorthand'
import 'keen-slider/keen-slider.min.css'

type Props = {
  data: Resource<{ data: DataModel[] }>
  loading: Accessor<boolean>
}

export function Drawer(props: Props) {
  const [scrolling, setScrolling] = createSignal(true)
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [slider, setSlider] = createSignal<KeenSliderInstance<{}, {}, KeenSliderHooks>>()
  const render = (ref: HTMLElement) => {
    let header = 0
    const slider = new KeenSlider(ref, {
      detailsChanged(slider) {
        // bounce off
        if (slider.track.details.progress >= 1) return
        const y = (slider.size - header) *
          slider.track.details.length *
          slider.track.details.progress *
          -1
        slider.container.style.transform = `translate3d(0, ${Math.round(y)}px, 0)`
      },
      initial: 0,
      renderMode: 'custom',
      rubberband: true,
      slideChanged(slider) {
        setScrolling(slider.track.details.rel === 0)
      },
      slides: (size, elements) => {
        header = elements[0].getBoundingClientRect().height
        const main = elements[1].getBoundingClientRect().height
        size -= header
        return [
          { origin: 0, size: main / size },
          {
            origin: -(size - header - main) / size,
            size: (size - header - main) / size
          }
        ]
      },
      vertical: true
    })
    setSlider(slider)
    createEffect(() => {
      if (scrolling()) return
    })
  }
  return <Presenter render={render} scrolling={scrolling} slider={slider} {...props} />
}

type PresenterProps = Props & {
  render: (ref: HTMLElement) => void
  scrolling: Accessor<boolean>
  // eslint-disable-next-line @typescript-eslint/ban-types
  slider: Accessor<KeenSliderInstance<{}, {}, KeenSliderHooks> | undefined>
}

function Presenter({ slider, scrolling, data, render, loading }: PresenterProps) {
  const Style = {
    Block: {
      box: css({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        backgroundColor: '#fff',
        display: 'grid',
        height: 'calc(100dvh - 144px)',
        overflowY: 'scroll',
        padding: '24px',
        paddingBlockStart: '24px',
        paddingBottom: 'calc(40px + 64px + 40px)',
        rowGap: '40px',
        width: '100%',
        ...position('fixed', '144px', undefined, undefined, 0, 1)
      }),
      container: css({
        ...size('100%', 0),
        ...position('absolute', undefined, undefined, 0, undefined, 1)
      }),
      inner: css({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        // top negative = header height
        ...position('absolute', '-50px', undefined, undefined, undefined, undefined)
      }),
      main: css({
        height: '100vh',
        paddingTop: '94px' // bar height 144 - header height 50
      }),
      scroll: css({
        padding: '0 20px',
        position: 'relative',
        ...size('100%', '100%')
      })
    },
    Modifier: {
      close: css({
        display: 'none'
      })
    }
  }
  const { Block, Modifier } = Style
  return (
    <>
      <Div class={Block.container}>
        <Div class={merge(['keen-slider', Block.inner])} ref={render}>
          <Section
            class={Block.scroll}
            data-keen-slider-scrollable
            style={{ 'overflow-y': scrolling() ? 'scroll' : 'visible' }}
          >
            <Header {...{ data, loading, slider }} />
            <Div class={merge(['keen-slider__slide', Block.main])} />
          </Section>
        </Div>
      </Div>
      <Div class={Block.box} classList={{ [Modifier.close]: scrolling() }}>
        <Suspense>
          <For each={data()?.data}>
            {data => (
              <Item {...{ data }} />
            )}
          </For>
        </Suspense>
        <Button
          class={css({
            columnGap: '8px',
            display: 'flex',
            padding: '11px 19px',
            transform: 'translateX(-50%)',
            ...position('fixed', undefined, undefined, '89px', '50%', undefined),
            ...shape('1px solid rgba(0, 0, 0, 0.08)', 'rgb(34, 34, 34)', '24px', undefined)
          })}
          onClick={() => slider()?.prev()}
        >
          <Span
            class={css({
              ...font('#fff', '12px', 600, '16px')
            })}
          >
            マップ
          </Span>
          <RiMapMap2Fill color='#fff' size={16} />
        </Button>
        <Footer />
      </Div>
    </>
  )
}
