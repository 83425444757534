import { css } from 'goober'
import mapboxgl from 'mapbox-gl'
import { IoSchool } from 'solid-icons/io'
import { createEffect } from 'solid-js'
import { shape, display } from '~/utils/css-shorthand'

type Props = {
  data: DataModel
  map: Accessor<MapboxMap | undefined>
  setSnippet: Setter<DataModel>
  snippet: Accessor<DataModel | undefined>
}

export function Pin({ snippet, data, map, setSnippet }: Props) {
  return <Presenter {...{ data, map, setSnippet, snippet }} />
}

function Presenter({ data, map, snippet, setSnippet }: Props) {
  const Style = {
    Block: {
      container: css({
        height: '28px',
        padding: '0 8px',
        ...display.grid.xy.center,
        ...shape(undefined, '#fff', '28px', 'rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 2px 4px')
      })
    },
    Modifier: {
      select: css({
        backgroundColor: '#222'
      })
    }
  }
  const { Block, Modifier } = Style
  createEffect(() => {
    if (!map()) return
    const pin = new mapboxgl.Marker({
      element: (
        // JSX cannot be used for the root element
        // ❌ <Button /> 🙆‍♀️ <button />
        <button
          class={Block.container}
          classList={{ [Modifier.select]: snippet()?.id === data.id }}
          onClick={() => setSnippet(data)}
        >
          <IoSchool color={snippet()?.id === data.id ? '#fff' : '#222'} />
        </button>
      ) as HTMLElement
    })
    const lngLatLike = {
      lat: data.lonLat.lat,
      lng: data.lonLat.lon
    }
    pin.setLngLat(lngLatLike)
    pin.addTo(map() as mapboxgl.Map)
  })
  return <></>
}
