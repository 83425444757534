import { css } from 'goober'
import { Div } from '~/components/html-living-standard'
import { shape } from '~/utils/css-shorthand'

type Props = ComponentProps<'div'>

export function Search(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ children }: Props) {
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        padding: '14px 24px 0 24px',
        position: 'relative'
      }),
      inner: css({
        display: 'flex',
        height: '56px',
        justifyContent: 'space-between',
        ...shape('0.5px solid rgba(0, 0, 0, 0.08)', '#fff', '100px', '0 3px 10px rgba(0, 0, 0, 0.1)')
      })
    }
  }
  const { Block } = Style
  return (
    <Div class={Block.container}>
      <Div class={Block.inner}>
        {children}
      </Div>
    </Div>
  )
}
