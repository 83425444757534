import { css } from 'goober'
import KeenSlider from 'keen-slider'
import { SiHotjar } from 'solid-icons/si'
import { useContext, For } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { App } from '~/contexts/App'
import { font, merge, shape } from '~/utils/css-shorthand'
import 'keen-slider/keen-slider.min.css'

type Props = {
  setShow: Setter<boolean>
}

export function Brands({ setShow }: Props) {
  const { name, setName } = useContext(App)
  const render = (ref: HTMLElement) => {
    new KeenSlider(ref, {
      created: () => {
        ref.style.visibility = 'visible'
      },
      slides: { perView: 2.5, spacing: 24 }
    })
  }
  const select = (name: string) => {
    setShow(false)
    setName(name)
  }
  return <Presenter name={name} render={render} select={select} />
}

type PresenterProps = {
  name: Accessor<string | undefined>
  render: (ref: HTMLElement) => void
  select: (name: string) => void
}

function Presenter({ name, render, select }: PresenterProps) {
  const Style = {
    Block: {
      icon: css({
        /*
        '& > div': {
          backgroundPositionY: '50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          filter: 'grayscale(1)',
          height: '100%'
        },
        */
        display: 'grid',
        height: '122px',
        padding: '16px',
        placeContent: 'center',
        ...shape('1px solid #ddd', '#f7f7f7', '12px', undefined)
      }),
      wrapper: css({})
    },
    Element: {
      slide: css({
        display: 'grid'
      }),
      text: css({
        margin: '8px 2px 0',
        ...font('#222', '14px', 400, '18px')
      })
    },
    Modifier: {
      on: css({
        ...shape('4px solid #222', '#f7f7f7', '12px', undefined)
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Div class={css({ overflow: 'hidden' })}>
      <Div class={merge(['keen-slider', Block.wrapper])} ref={render}>
        <For
          each={[
            {
              n: '明光義塾'
            },
            {
              n: '英才個別学院'
            },
            {
              n: 'エール学院'
            },
            {
              n: '誠泉塾'
            }
          ]}
        >
          {({ n }) => (
            <Button
              class={merge(['keen-slider__slide', Element.slide])}
              onClick={() => select(n)}
            >
              <Div class={Block.icon} classList={{ [Modifier.on]: name() === n }}>
                <SiHotjar color='#bfbfbf' size={64} />
              </Div>
              <Span class={Element.text}>{n}</Span>
            </Button>
          )}
        </For>
      </Div>
    </Div>
  )
}
