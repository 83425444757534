import { createContext } from 'solid-js'

type Props = {
  address: Accessor<string>
  course: Accessor<string[]>
  data: Resource<{
    data: DataModel[]
    lat: number
    lon: number
    radius: number
  }>
  favorite: Accessor<string[]>
  feature: Accessor<string[]>
  grade: Accessor<string[]>
  map: Accessor<MapboxMap | undefined>
  name: Accessor<string | undefined>
  radius: Accessor<Radius>
  // rooms: Accessor<DataModel[] | undefined>
  setCourse: (value: string) => void
  setFavorite: (value: string) => void
  setFeature: (value: string) => void
  setGrade: (value: string) => void
  setName: Setter<string | undefined>
  setRadius: Setter<Radius>
  setShowFavorite: Setter<boolean>
  // setRooms: Setter<DataModel[]>
  setType: (value: string) => void
  showFavorite: Accessor<boolean>
  type: Accessor<string[]>
}

export const App = createContext({} as Props)
