import { css } from 'goober'
import mapboxgl from 'mapbox-gl'
import { RiMapMapPinUserFill } from 'solid-icons/ri'
import { createMemo, createEffect, createSignal, onMount } from 'solid-js'
import { position, size, shape } from '~/utils/css-shorthand'

type Props = {
  center: Accessor<Center>
  map: Accessor<MapboxMap | undefined>
}

export function Point(props: Props) {
  const center = createMemo(() => {
    return { lat: props.center()[1], lon: props.center()[0] }
  })

  return <Presenter center={center} map={props.map} />
}

type PresenterProps = {
  center: Accessor<{ lat: number, lon: number }>
  map: Accessor<MapboxMap | undefined>
}

function Presenter({ center, map }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '&::before': {
          content: '""',
          transform: 'translateX(-50%) translateY(-50%)',
          ...position('absolute', '45%', undefined, undefined, '50%', -1),
          ...size('36px', '36px'),
          ...shape(undefined, '#fff', '100%', undefined)
        },
        display: 'flex',
        pointerEvents: 'none',
        position: 'relative'
      })
    }
  }

  const { Block } = Style

  const [point, setPoint] = createSignal<mapboxgl.Marker>()

  onMount(() => {
    setPoint(
      new mapboxgl.Marker({
        element: (
          // JSX cannot be used for the root element
          // ❌ <Button /> 🙆‍♀️ <button />
          <button class={Block.container}>
            <RiMapMapPinUserFill color='rgb(0, 157, 224)' size={64} />
          </button>
        ) as HTMLDivElement
      })
    )
  })

  createEffect(() => {
    if (!map()) return
    point()?.setLngLat(center())
    point()?.addTo(map() as mapboxgl.Map)
  })

  return <></>
}
