import { css } from 'goober'
import { RiMapWalkLine, RiMapRunLine, RiMapRidingLine, RiMapCarLine } from 'solid-icons/ri'
import { useContext, For } from 'solid-js'
import { Dynamic } from 'solid-js/web'
import { Div } from '~/components/html-living-standard'
import { Button, Span } from '~/components/html-living-standard'
import { App } from '~/contexts/App'
import { font, position, size } from '~/utils/css-shorthand'

export function Feature() {
  const { radius, setRadius } = useContext(App)
  const items = [
    { icon: RiMapWalkLine, label: '1km圏内' },
    { icon: RiMapRunLine, label: '2km圏内' },
    { icon: RiMapRidingLine, label: '3km圏内' },
    { icon: RiMapCarLine, label: '4km圏内' }
  ]
  return <Presenter {...{ items, radius, setRadius }} />
}

type PresenterProps = {
  items: { icon: IconTypes, label: string }[]
  radius: Accessor<Radius>
  setRadius: Setter<Radius>
}

function Presenter({ radius, setRadius, items }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        backgroundColor: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0 0 6px',
        columnGap: '16px',
        display: 'flex',
        height: '74px',
        overflowX: 'scroll',
        padding: '0 24px'
      })
    },
    Element: {
      button: css({
        marginTop: '12px',
        padding: '4px 0 14px',
        span: {
          display: 'grid',
          minWidth: '64px',
          placeItems: 'center',
          rowGap: '4px'
        }
      }),
      label: css({
        position: 'relative',
        whiteSpace: 'nowrap',
        ...font('#717171', '12px', 600, '16px')
      })
    },
    Modifier: {
      on: css({
        '&::after': {
          backgroundColor: '#000',
          content: '""',
          ...position('absolute', undefined, undefined, '-14px', 0, undefined),
          ...size('100%', '2px')
        },
        color: '#222'
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Div class={Block.container}>
      <For each={items}>
        {({ icon, label }, index) => (
          <Button class={Element.button} onClick={() => setRadius(index() + 1)}>
            <Span>
              <Dynamic color={index() === 0 ? '#222' : '#717171'} component={icon} size={24} />
              <Span class={Element.label} classList={{ [Modifier.on]: radius() === index() + 1 }}>{label}</Span>
            </Span>
          </Button>
        )}
      </For>
    </Div>
  )
}
