import { css } from 'goober'
import { RiMediaEqualizerLine } from 'solid-icons/ri'
import { createSignal } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Drawer } from '~/components/root/filter'
import { size, display, shape } from '~/utils/css-shorthand'

export function Filter() {
  return <Presenter />
}

function Presenter() {
  const [show, setShow] = createSignal(false)
  const Style = {
    Block: {
      container: css({
        alignItems: 'center',
        display: 'flex',
        width: '56px'
      }),
      inner: css({
        margin: '0 10px',
        ...display.grid.xy.center,
        ...size('36px', '36px'),
        ...shape('1px solid #ddd', '#fff', '25px', undefined)
      })
    }
  }
  const { Block } = Style
  return (
    <Div class={Block.container}>
      <Button aria-label='toggle' class={Block.inner} onClick={() => setShow(!show())}>
        <Span>
          <RiMediaEqualizerLine color='#222' size={16} />
        </Span>
      </Button>
      <Drawer {...{ setShow, show }} />
    </Div>
  )
}
