import { css } from 'goober'
import { Show, For, createSignal, onMount, Suspense } from 'solid-js'
import { Div } from '~/components/html-living-standard'
import { Header, Item, Footer } from '~/components/root/favorite'
import { position, shape, size } from '~/utils/css-shorthand'

type Props = {
  favorite: Accessor<string[]>
  setShow: Setter<boolean>
  show: Accessor<boolean>
}

export function Drawer(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ favorite, setShow, show }: Props) {
  const [isSSR, setIsSSR] = createSignal(true)
  onMount(() => setIsSSR(false))
  const Style = {
    Block: {
      box: css({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        backgroundColor: '#f7f7f7',
        display: 'grid',
        height: 'calc(100dvh - 131px)',
        overflowY: 'scroll',
        padding: '24px',
        paddingBlockStart: '24px',
        // paddingBottom: 'calc(40px + 64px + 40px)',
        rowGap: '40px',
        width: '100%',
        ...position('fixed', undefined, undefined, undefined, 0, 1)
      }),
      container: css({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        marginTop: '12px',
        ...position('fixed', 0, undefined, undefined, undefined, undefined),
        ...size('100%', '100dvh')
      }),
      layer: css({
        backgroundColor: 'rgb(34, 34, 34)',
        height: '100dvh'
      }),
      screen: css({
        width: '100%',
        ...position('fixed', 0, undefined, undefined, 0, 2)
      })
    },
    Element: {
      button: css({
        display: 'grid',
        marginBottom: '12px',
        padding: '24px',
        ...size('100%', '324px'),
        ...shape(undefined, '#fff', '24px', '0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%)')
      })
    },
    Modifier: {
      close: css({
        transform: 'translateY(100%)',
        transition: 'transform 400ms'
      }),
      hide: css({
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 400ms'
      }),
      off: css({
        pointerEvents: 'none'
      }),
      on: css({
        pointerEvents: 'auto'
      }),
      open: css({
        transform: 'translateY(0)',
        transition: 'transform 400ms'
      }),
      show: css({
        opacity: 0.6,
        pointerEvents: 'auto',
        transition: 'opacity 400ms'
      })
    }
  }
  const { Block, Modifier } = Style
  return (
    <Div class={Block.screen} classList={{ [Modifier.on]: show(), [Modifier.off]: !show() }}>
      <Div class={Block.layer} classList={{ [Modifier.show]: show(), [Modifier.hide]: !show() }} />
      <Div class={Block.container} classList={{ [Modifier.open]: show(), [Modifier.close]: !show() }}>
        <Header {...{ setShow, show }} />
        <Div class={Block.box}>
          <Show when={!isSSR()}>
            <Suspense>
              <For each={favorite()}>
                {id => (
                  <Item id={id} />
                )}
              </For>
            </Suspense>
          </Show>
        </Div>
        <Footer {...{ setShow }} />
      </Div>
    </Div>
  )
}
