import { css } from 'goober'
import { RiSystemSearchLine } from 'solid-icons/ri'
import { createSignal } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Drawer as AddressDrawer } from '~/components/root/address'
import { Drawer as BrandDrawer } from '~/components/root/brand'
import { Header, Brands, Footer } from '~/components/root/location'
import { position, shape, size, font } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
  show: Accessor<boolean>
}

export function Drawer(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ setShow, show }: Props) {
  const [showAddressDrawer, setShowAddressDrawer] = createSignal(false)
  const [showBrandDrawer, setShowBrandDrawer] = createSignal(false)
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        marginTop: '12px',
        ...position('fixed', 0, undefined, undefined, undefined, undefined),
        ...size('100%', '100dvh')
      }),
      layer: css({
        backgroundColor: 'rgb(34, 34, 34)',
        height: '100dvh'
      }),
      screen: css({
        width: '100%',
        ...position('fixed', 0, undefined, undefined, 0, 2)
      })
    },
    Element: {
      button: css({
        display: 'grid',
        marginBottom: '12px',
        padding: '24px',
        ...size('100%', '324px'),
        ...shape(undefined, '#fff', '24px', '0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%)')
      })
    },
    Modifier: {
      close: css({
        transform: 'translateY(100%)',
        transition: 'transform 400ms'
      }),
      hide: css({
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 400ms'
      }),
      off: css({
        pointerEvents: 'none'
      }),
      on: css({
        pointerEvents: 'auto'
      }),
      open: css({
        transform: 'translateY(0)',
        transition: 'transform 400ms'
      }),
      show: css({
        opacity: 0.6,
        pointerEvents: 'auto',
        transition: 'opacity 400ms'
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Div class={Block.screen} classList={{ [Modifier.on]: show(), [Modifier.off]: !show() }}>
      <Div class={Block.layer} classList={{ [Modifier.show]: show(), [Modifier.hide]: !show() }} />
      <Div class={Block.container} classList={{ [Modifier.open]: show(), [Modifier.close]: !show() }}>
        <Header {...{ setShow, show }} />
        <Div
          class={css({
            backgroundColor: '#f7f7f7',
            height: 'calc(100dvh - 60px - 71px)',
            overflowY: 'scroll',
            paddingBlockStart: '12px',
            paddingInline: '12px'
          })}
        >
          <Div class={Element.button}>
            <Button
              class={css({
                alignItems: 'center',
                border: '1px solid rgb(176, 176, 176)',
                borderRadius: '12px',
                columnGap: '10px',
                display: 'flex',
                height: '60px',
                paddingInline: '20px',
                ...font('#222', '14px', 400, '18px')
              })}
              onClick={() => setShowAddressDrawer(true)}
            >
              <RiSystemSearchLine size={16} />
              <Span>住所・ランドマークから検索</Span>
            </Button>
          </Div>
          <Div class={Element.button}>
            <Button
              class={css({
                alignItems: 'center',
                backgroundColor: '#eee',
                border: '1px solid rgb(176, 176, 176)',
                borderRadius: '12px',
                columnGap: '10px',
                display: 'flex',
                height: '60px',
                paddingInline: '20px',
                ...font('#222', '14px', 400, '18px')
              })}
              // onClick={() => setShowBrandDrawer(true)}
            >
              <RiSystemSearchLine size={16} />
              <Span>ブランド・教室名から検索</Span>
            </Button>
            <Brands {...{ setShow }} />
          </Div>
          <AddressDrawer setShow={setShow} setShow2={setShowAddressDrawer} show2={showAddressDrawer} />
          <BrandDrawer setShow={setShow} setShow2={setShowBrandDrawer} show2={showBrandDrawer} />
        </Div>
        <Footer {...{ setShow }} />
      </Div>
    </Div>
  )
}
