import { css } from 'goober'
import { AiOutlineHeart } from 'solid-icons/ai'
import { useContext } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { App } from '~/contexts/App'
import { font } from '~/utils/css-shorthand'

export function Footer() {
  const { setShowFavorite } = useContext(App)
  const show = () => {
    setShowFavorite(true)
  }
  return <Presenter {...{ show }} />
}

type PresenterProps = {
  show: () => void
}

function Presenter({ show }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        borderTop: '1px solid rgb(235, 235, 235)',
        bottom: 0,
        display: 'grid',
        height: '64px',
        left: 0,
        placeContent: 'center',
        position: 'fixed',
        width: '100%',
        zIndex: 1
      })
    },
    Element: {
      button: css({
        display: 'grid',
        placeItems: 'center'
      }),
      label: css({
        ...font('#717171', '10px', 600, undefined)
      })
    }
  }
  const { Block, Element } = Style
  return (
    <Div class={Block.container}>
      <Button class={Element.button} onClick={() => show()}>
        <AiOutlineHeart color='#717171' size={32} />
        <Span class={Element.label}>お気に入り</Span>
      </Button>
    </Div>
  )
}
