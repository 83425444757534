import { css } from 'goober'
import { RiSystemCloseLine } from 'solid-icons/ri'
import { Div, Button } from '~/components/html-living-standard'
import { Form } from '~/components/root/address'
import { position, size } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
  setShow2: Setter<boolean>
  show2: Accessor<boolean>
}

export function Drawer(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ setShow, setShow2, show2 }: Props) {
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        marginTop: '12px',
        ...position('fixed', 0, undefined, undefined, undefined, undefined),
        ...size('100%', '100dvh')
      }),
      layer: css({
        backgroundColor: 'rgb(34, 34, 34)',
        height: '100dvh'
      }),
      screen: css({
        width: '100%',
        ...position('fixed', 0, undefined, undefined, 0, 2)
      })
    },
    Modifier: {
      close: css({
        transform: 'translateY(100%)',
        transition: 'transform 400ms'
      }),
      hide: css({
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 400ms'
      }),
      off: css({
        pointerEvents: 'none'
      }),
      on: css({
        pointerEvents: 'auto'
      }),
      open: css({
        transform: 'translateY(0)',
        transition: 'transform 400ms'
      }),
      show: css({
        opacity: 0.6,
        pointerEvents: 'auto',
        transition: 'opacity 400ms'
      })
    }
  }
  const { Block, Modifier } = Style
  return (
    <Div class={Block.screen} classList={{ [Modifier.on]: show2(), [Modifier.off]: !show2() }}>
      <Div class={Block.layer} classList={{ [Modifier.show]: show2(), [Modifier.hide]: !show2() }} />
      <Div class={Block.container} classList={{ [Modifier.open]: show2(), [Modifier.close]: !show2() }}>
        <Button
          aria-label='close'
          class={css({
            ...position('absolute', '16px', undefined, undefined, '24px', undefined),
            display: 'flex'
          })}
          onClick={() => setShow2(false)}
        >
          <RiSystemCloseLine />
        </Button>
        <Div class={css({ padding: '24px' })}>
          <Form {...{ setShow, setShow2, show2 }} />
        </Div>
      </Div>
    </Div>
  )
}
