import { css } from 'goober'
import { RiMapNavigationFill } from 'solid-icons/ri'
import { Div, Article, Button } from '~/components/html-living-standard'
import { Header } from '~/components/root/tutorial'
import { position, font } from '~/utils/css-shorthand'

type Props = {
  center: Accessor<Center>
  setShow: Setter<boolean>
  show: Accessor<boolean>
}

export function Drawer(props: Props) {
  const onClick = () => {
    props.setShow(false)
    const target = document.querySelector('[aria-label="here"]') as HTMLButtonElement
    target.click()
  }

  return <Presenter onClick={onClick} {...props} />
}

type PresenterProps = Props & {
  onClick: () => void
}

function Presenter({ setShow, show, onClick, center }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        marginTop: '12px',
        overflowY: 'scroll',
        ...position('fixed', undefined, undefined, 0, undefined, undefined)
      }),
      layer: css({
        backgroundColor: 'rgb(34, 34, 34)',
        height: '100dvh'
      }),
      screen: css({
        width: '100%',
        ...position('fixed', 0, undefined, undefined, 0, 2)
      })
    },
    Element: {
      button: {
        primary: css({
          backgroundColor: 'rgb(34, 34, 34)',
          borderRadius: '8px',
          padding: '12px 16px',
          whiteSpace: 'nowrap',
          ...font('#f7f7f7', '16px', 600, '22px')
        }),
        secondary: css({
          marginBlockStart: '16px',
          textDecoration: 'underline',
          ...font('rgb(34, 34, 34)', '16px', 600, '22px')
        })
      },
      description: css({
        marginBlockEnd: '40px',
        ...font('#222', '16px', 400, '20px')
      }),
      title: css({
        marginBlock: '16px 6px',
        ...font('#222', '36px', 600, '38px')
        //...font('#222', '38px', 600, '40px')
      })
    },
    Modifier: {
      close: css({
        transform: 'translateY(100%)',
        transition: 'transform 400ms'
      }),
      hide: css({
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 400ms'
      }),
      off: css({
        pointerEvents: 'none'
      }),
      on: css({
        pointerEvents: 'auto'
      }),
      open: css({
        transform: 'translateY(0)',
        transition: 'transform 400ms'
      }),
      show: css({
        opacity: 0.6,
        pointerEvents: 'auto',
        transition: 'opacity 400ms'
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Div class={Block.screen} classList={{ [Modifier.on]: show(), [Modifier.off]: !show() }}>
      <Div
        class={Block.layer}
        classList={{ [Modifier.show]: show(), [Modifier.hide]: !show() }}
        onClick={() => setShow(false)}
      />
      <Article class={Block.container} classList={{ [Modifier.open]: show(), [Modifier.close]: !show() }}>
        <Header {...{ center, setShow }} />
        <Div class={css({ height: '100%', padding: '40px 26px 50px', textAlign: 'center' })}>
          <Div class={Element.title}>現在地が違いますか？</Div>
          <Div class={Element.description}>
            ご利用端末で位置情報の使用を許可して頂くと、正確な位置情報がご利用頂けます。
          </Div>
          <Div class={css({ display: 'grid' })}>
            <Button
              class={Element.button.primary}
              onClick={onClick}
            >
              位置情報の使用を許可する
            </Button>
            <Button class={Element.button.secondary} onClick={() => setShow(false)}>
              あとで許可する場合は地図右上の<RiMapNavigationFill color={'#b0b0b0'} size={20} />をタップ
            </Button>
          </Div>
        </Div>
      </Article>
    </Div>
  )
}
