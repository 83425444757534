import { splitProps } from 'solid-js'

type Props = ComponentProps<'circle'>

export function Circle(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <circle {...attributes}>{component.children}</circle>
}
