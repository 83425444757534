import { createGeolocation } from '@solid-primitives/geolocation'
import { css } from 'goober'
import { RiMapNavigationFill } from 'solid-icons/ri'
import { createSignal, createReaction, createEffect } from 'solid-js'
import { Button } from '~/components/html-living-standard'
import { position, size, shape, display } from '~/utils/css-shorthand'

type Props = {
  map: Accessor<MapboxMap | undefined>
  setLoading: Setter<boolean>
}

export function Here({ map, setLoading }: Props) {
  const [cache, setCache] = createSignal<Center>()
  const [error, setError] = createSignal(false)
  const [useHere, setUseHere] = createSignal(false)
  const track = createReaction(() => {
    const [location] = createGeolocation()
    setLoading(true)
    createEffect(() => {
      try {
        const location$ = location()
        if (typeof location$ === 'undefined') return
        const { longitude, latitude } = location$
        setCache([longitude, latitude])
        map()?.flyTo({ center: [longitude, latitude] })
      } catch {
        setLoading(false)
        setError(true)
      }
    })
  })
  track(() => useHere())
  const onClick = () => {
    if (cache()) return map()?.flyTo({ center: cache() })
    setUseHere(true)
  }
  return <Presenter {...{ cache, error, onClick }} />
}

type PresenterProps = {
  cache: Accessor<Center | undefined>
  error: Accessor<boolean>
  onClick: () => void
}

function Presenter({ cache, onClick }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        ...display.grid.xy.center,
        ...position('fixed', '160px', '16px', undefined, undefined, 1),
        ...size('40px', '40px'),
        ...shape(undefined, '#fff', '8px', '0 6px 16px rgba(0, 0, 0, 0.12)')
      })
    }
  }
  const { Block } = Style
  return (
    <Button aria-label='here' class={Block.container} onClick={onClick}>
      <RiMapNavigationFill color={cache() ? '#222' : '#b0b0b0'} size={20} />
    </Button>
  )
}
