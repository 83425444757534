import { css } from 'goober'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

type Props = {
  center: Accessor<Center>
  setCenter: Setter<Center>
  setLoading: Setter<boolean>
  setMap: Setter<MapboxMap>
  setSnippet: Setter<DataModel | undefined>
}

export function Map({ setMap, setCenter, center, setLoading, setSnippet }: Props) {
  const render = (ref: HTMLDivElement) => {
    // console.log(center())
    const map = new mapboxgl.Map({
      accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
      attributionControl: false,
      center: center(),
      container: ref,
      // 画面回転を不可とすると、一般的な方法での拡大縮小が不可になります
      // 一般的な方法での拡大縮小を可とすると、画面回転もまた可となります
      // 画面回転を不可とし、拡大縮小も可とする方法は現時点ではありません
      // dragRotate: false,
      // pitchWithRotate: false,
      // touchZoomRotate: false,
      style: import.meta.env.VITE_MAPBOX_STYLE,
      zoom: 13
    })
    map.on('load', () => setMap(map))
    map.on('movestart', () => {
      setLoading(true)
      setSnippet(undefined)
    })
    map.on('moveend', ({ target }) => {
      const element = document.querySelector('span.mapboxgl-marker')
      element?.remove()
      setCenter(Object.values(target.getCenter()) as Center)
    })
  }
  return <Presenter {...{ render }} />
}

type PresenterProps = {
  render: (ref: HTMLDivElement) => void
}

function Presenter({ render }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '.mapboxgl-control-container': {
          display: 'none'
        },
        height: '100dvh',
        overflow: 'hidden',
        position: 'relative'
      })
    }
  }
  const { Block } = Style
  // JSX cannot be used for the root element
  // ❌ <Div /> 🙆‍♀️ <div />
  return <div class={Block.container} ref={ref => render(ref)} />
}
