import { css } from 'goober'
import { RiSystemCloseLine } from 'solid-icons/ri'
import { Header as HTMLHeader, Button, H2 } from '~/components/html-living-standard'
import { display, position, font } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
}

export function Header(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ setShow }: Props) {
  const Style = {
    Block: {
      container: css({
        ...display.grid.xy.center,
        ...position('sticky', 0, undefined, undefined, undefined, undefined),
        backgroundColor: '#fff',
        borderBottom: '1px solid rgb(235, 235, 235)',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        height: '48px'
      })
    },
    Element: {
      button: css({
        ...position('absolute', '16px', undefined, undefined, '24px', undefined),
        display: 'flex'
      }),
      heading: css({
        ...font('#222', '16px', 600, '20px')
      })
    }
  }
  const { Block, Element } = Style
  return (
    <HTMLHeader class={Block.container}>
      <Button aria-label='close' class={Element.button} onClick={() => setShow(false)}>
        <RiSystemCloseLine />
      </Button>
      <H2 class={Element.heading}>お気に入り</H2>
    </HTMLHeader>
  )
}
