import { css } from 'goober'
import { createSignal, createEffect } from 'solid-js'
import { Form as HTMLForm, Input } from '~/components/html-living-standard'
import { size, shape, font } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
  setShow2: Setter<boolean>
  show2: Accessor<boolean>
}

export function Form({ show2 }: Props) {
  const [, setQuery] = createSignal('')

  let ref!: HTMLInputElement

  createEffect(() => {
    if (!show2()) return
    setTimeout(() => {
      ref.focus()
    }, 400) // animation
  })

  const onInput = ({ target }: Event) => {
    const value = (target as HTMLInputElement).value
    setQuery(value)
  }

  return <Presenter ref={ref} {...{ onInput }} />
}

type PresenterProps = {
  onInput: (event: Event) => void
  ref: HTMLInputElement
}

function Presenter({ ref, onInput }: PresenterProps) {
  const Style = {
    Element: {
      input: css({
        marginTop: '16px',
        padding: '0 20px',
        ...size('100%', '60px'),
        ...font('#222', '16px', 400, '20px'),
        ...shape(undefined, '#f7f7f7', '12px', undefined)
      })
    }
  }
  const { Element } = Style
  return (
    <>
      <HTMLForm>
        <Input class={Element.input} onInput={onInput} placeholder='ブランド・教室名から検索' ref={ref} type='text' />
      </HTMLForm>
    </>
  )
}
