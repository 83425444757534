import { css } from 'goober'
import { RiUserParentLine, RiUserTeamLine, RiUserUser2Line, RiUserGroup2Line } from 'solid-icons/ri'
import { useContext, For } from 'solid-js'
import { Dynamic } from 'solid-js/web'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Group } from '~/components/root/filter/Group'
import { App } from '~/contexts/App'
import { shape, font } from '~/utils/css-shorthand'

export function Type() {
  const { type, setType } = useContext(App)
  const items = [
    { icon: RiUserTeamLine, label: '集団指導' },
    { icon: RiUserParentLine, label: '個別指導' },
    { icon: RiUserUser2Line, label: '家庭教師' },
    { icon: RiUserGroup2Line, label: '通信・ネット' } // '通信教育・ネット学習'
  ]
  return <Presenter {...{ items, setType, type }} />
}

type PresenterProps = {
  items: { icon: IconTypes, label: string }[]
  setType: (value: string) => void
  type: Accessor<string[]>
}

function Presenter({ items, type, setType }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(2, auto)'
      })
    },
    Element: {
      button: css({
        display: 'flex',
        flexFlow: 'column',
        height: '130px',
        justifyContent: 'space-between',
        padding: '16px',
        ...shape('1px solid #b0b0b080', '#fff', '16px', undefined),
        ...font('#222', '16px', 600, undefined)
      })
    },
    Modifier: {
      on: css({
        backgroundColor: '#f7f7f7',
        borderColor: 'rgb(1, 1, 1)',
        borderWidth: '2px'
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Group heading='受講タイプ'>
      <Div class={Block.container}>
        <For each={items}>
          {({ icon, label }) => (
            <Button
              class={Element.button}
              classList={{ [Modifier.on]: type().includes(label) }}
              onClick={() => setType(label)}
            >
              <Dynamic color='#222' component={icon} size={32} />
              <Span>{label}</Span>
            </Button>
          )}
        </For>
      </Div>
    </Group>
  )
}
