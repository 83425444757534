import { css } from 'goober'
import { Section, H3 } from '~/components/html-living-standard'
import { font, size } from '~/utils/css-shorthand'

type Props = ComponentProps<'section'> & {
  heading: string
}

export function Group(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ heading, children }: Props) {
  const Style = {
    Block: {
      container: css({
        '&::after': {
          backgroundColor: 'rgb(221, 221, 221)',
          content: '""',
          display: 'flex',
          marginBlock: '24px',
          ...size('100%', '1px')
        },
        paddingInline: '24px'
      })
    },
    Element: {
      heading: css({
        marginBlockEnd: '24px',
        ...font('#222', '18px', 600, '22px')
      })
    }
  }
  const { Block, Element } = Style
  return (
    <Section class={Block.container}>
      <H3 class={Element.heading}>{heading}</H3>
      {children}
    </Section>
  )
}
