import { css } from 'goober'
import { For } from 'solid-js'
import { useContext } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Group } from '~/components/root/filter/Group'
import { App } from '~/contexts/App'
import { font, shape } from '~/utils/css-shorthand'

export function Feature() {
  const { feature, setFeature } = useContext(App)
  const items = [
    { label: '保護者参加' },
    { label: '体験授業' },
    { label: 'オリジナルテキスト' },
    { label: '駅近く' },
    { label: '完全マンツーマン指導' },
    { label: '自習室完備' },
    { label: '送迎あり' },
    { label: '電話質問' },
    { label: '添削サービス' }
  ]
  return <Presenter {...{ feature, items, setFeature }} />
}

type PresenterProps = {
  feature: Accessor<string[]>
  items: { label: string }[]
  setFeature: (value: string) => void
}

function Presenter({ setFeature, items, feature }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '> button:last-child': {
          marginRight: '24px'
        },
        columnGap: '10px',
        display: 'flex',
        marginRight: '-24px',
        overflowX: 'scroll'
      })
    },
    Element: {
      button: css({
        minWidth: 'fit-content',
        padding: '10px 20px',
        ...shape('1px solid #ddd', undefined, '30px', undefined),
        ...font('#222', '14px', 400, '18px')
      })
    },
    Modifier: {
      on: css({
        backgroundColor: 'rgb(34, 34, 34)',
        borderColor: 'rgb(34, 34, 34)',
        ...font('#fff', '14px', 400, '18px')
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Group heading='特色から探す'>
      <Div class={Block.container}>
        <For each={items}>
          {({ label }) => (
            <Button
              class={Element.button}
              classList={{ [Modifier.on]: feature().includes(label) }}
              onClick={() => setFeature(label)}
            >
              <Span>{label}</Span>
            </Button>
          )}
        </For>
      </Div>
    </Group>
  )
}
