import { css } from 'goober'
import { Header, Div } from '~/components/html-living-standard'
import { position } from '~/utils/css-shorthand'

type Props = ComponentProps<'header'>

export function Bar(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ children }: Props) {
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        width: '100%',
        ...position('fixed', 0, undefined, undefined, undefined, 2)
      }),
      inner: css({
        columnGap: '16px',
        display: 'grid'
      })
    }
  }
  const { Block } = Style
  return (
    <Header class={Block.container}>
      <Div class={Block.inner}>
        {children}
      </Div>
    </Header>
  )
}
