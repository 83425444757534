import { splitProps } from 'solid-js'

type Props = ComponentProps<'a'>

export function A(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <a {...attributes}>{component.children}</a>
}
