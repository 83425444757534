import { css } from 'goober'
import { AiFillHeart, AiOutlineHeart } from 'solid-icons/ai'
import { useContext } from 'solid-js'
import { SmartphoneCF } from '~/components/general'
import { A, Div, Button, P } from '~/components/html-living-standard'
import { App } from '~/contexts/App'
import { display, position, size, font, shape } from '~/utils/css-shorthand'

type Props = {
  data: DataModel
}

export function Item(props: Props) {
  const { favorite, setFavorite } = useContext(App)
  return <Presenter favorite={favorite} setFavorite={setFavorite} {...props} />
}

type PresenterProps = Props & {
  favorite: Accessor<string[]>
  setFavorite: (value: string) => void
}

function Presenter({ data, favorite, setFavorite }: PresenterProps) {
  const Style = {
    Block: {
      bottom: css({
        display: 'grid',
        marginTop: '12px',
        rowGap: '2px'
      }),
      top: css({
        '&::after': {
          backgroundImage: 'linear-gradient(180deg, transparent 80%, rgba(0, 0, 0, 0.25))',
          borderRadius: '12px',
          content: '""',
          ...position('absolute', 0, undefined, undefined, 0, 2),
          ...size('100%', '100%')
        },
        aspectRatio: '20 / 19',
        backgroundColor: '#eee',
        borderRadius: '12px',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: 'calc(100vw - 48px)',
        zIndex: 0
      })
    },
    Element: {
      favorite: css({
        ...display.grid.xy.center,
        ...position('absolute', '16px', '13px', undefined, undefined, undefined),
        ...size('36px', '36px'),
        ...shape(
          '1px solid rgba(0, 0, 0, .08)',
          '#fff',
          '100%',
          '0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, .18)'
        ),
        zIndex: 1
      }),
      feature: css({
        ...font('#717171', '15px', 400, '19px')
      }),
      name: css({
        ...font('#222', '15px', 600, '19px')
      }),
      web: css({
        ...font('#717171', '15px', 400, '19px')
      })
    }
  }
  const { Block, Element } = Style
  return (
    <Div class={css({ position: 'relative' })}>
      <A href={`rooms/${data.id}`}>
        <Div>
          <Div class={Block.top}>
            <SmartphoneCF
              imageId={data.id}
              scale={0.75}
              top={0}
              width={321}
              y={-100}
            />
          </Div>
          <Div class={Block.bottom}>
            <P class={Element.name}>{data.name}</P>
            <P class={Element.feature}>{data.address}</P>
            <P class={Element.feature}>{data.feature.join('・')}</P>
          </Div>
        </Div>
      </A>
      <Button aria-label='add or remove favorite' class={Element.favorite} onClick={() => setFavorite(data.id)}>
        {favorite().includes(data.id)
          ? <AiFillHeart color='#ff385c' size={16} />
          : <AiOutlineHeart color='#222' size={16} />}
      </Button>
    </Div>
  )
}
