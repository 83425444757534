import { css } from 'goober'
import { RiSystemCloseLine } from 'solid-icons/ri'
import { For, Suspense, createMemo } from 'solid-js'
import { SmartphoneCF } from '~/components/general'
import { Div, A, P, Button } from '~/components/html-living-standard'
import { position, size, shape, display, font, clamp } from '~/utils/css-shorthand'

type Props = {
  setSnippet: Setter<DataModel | undefined>
  snippet: Accessor<DataModel | undefined>
}

export function Snippet(props: Props) {
  return <Presenter {...props} />
}

type PresenterProps = Props

function Presenter({ snippet, setSnippet }: PresenterProps) {
  const id = createMemo(() => snippet()?.id)
  const Style = {
    Block: {
      box: css({
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto 1fr',
        position: 'relative',
        ...size('calc(100vw - 48px)', '126px'),
        ...shape(undefined, '#fff', '12px', '0 6px 20px rgba(0,0,0,0.2)')
      }),
      container: css({
        position: 'relative'
      }),
      left: css({
        backgroundColor: '#eee',
        borderBottomLeftRadius: '12px',
        borderTopLeftRadius: '12px',
        height: '126px',
        overflow: 'hidden',
        position: 'relative',
        width: '126px'
      }),
      right: css({
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        padding: '12px'
      }),
      wrapper: css({
        width: '100%',
        ...display.grid.xy.center,
        ...position('fixed', undefined, undefined, '98px', undefined, undefined)
      })
    },
    Element: {
      button: css({
        ...position('absolute', '9px', undefined, undefined, '9px', 1),
        ...size('24px', '24px'),
        ...display.flex.xy.center,
        backgroundColor: 'rgba(0, 0, 0, .4)',
        borderRadius: '50%',
        zIndex: 2
      }),
      feature: css({
        ...clamp(2, 18),
        ...font('#717171', '14px', 400, '18px')
      }),
      name: css({
        marginBottom: '4px',
        ...clamp(2, 18),
        ...font('#222', '14px', 600, '18px')
      }),
      review: css({
        ...font('#222', '14px', 600, '18px')
      }),
      web: css({
        ...clamp(2, 18),
        ...font('#717171', '14px', 400, '18px')
      })
    }
  }
  const { Block, Element } = Style
  return (
    <Div class={Block.wrapper}>
      <Div class={Block.container}>
        <Button aria-label='open' class={Element.button} onClick={() => setSnippet(undefined)}>
          <RiSystemCloseLine color='#fff' />
        </Button>
        <A class={Block.box} href={`/rooms/${String(snippet()?.id)}`}>
          <Div class={Block.left}>
            <For each={[id() as string]}>
              {() => (
                <Suspense>
                  <SmartphoneCF imageId={id() as string} scale={0.225} top={-320} width={96} y={0} />
                </Suspense>
              )}
            </For>
          </Div>
          <Div class={Block.right}>
            <P class={Element.name}>{snippet()?.name}</P>
            <P class={Element.feature}>{snippet()?.feature.join('・')}</P>
            <P class={Element.review}>{snippet()?.review.length ? 'AIによる算出スコアありの教室' : ''}</P>
          </Div>
        </A>
      </Div>
    </Div>
  )
}
