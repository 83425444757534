import { css } from 'goober'
import { useContext, For } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Group } from '~/components/root/filter'
import { App } from '~/contexts/App'
import { shape, font } from '~/utils/css-shorthand'

export function Grade() {
  const { grade, setGrade } = useContext(App)
  const items = [
    { label: '未就学児' },
    { label: '小1' },
    { label: '小2' },
    { label: '小3' },
    { label: '小4' },
    { label: '小5' },
    { label: '小6' },
    { label: '中1' },
    { label: '中2' },
    { label: '中3' },
    { label: '高1' },
    { label: '高2' },
    { label: '高3' },
    { label: '既卒' }
    /*
    { label: '小4、6' },
    { label: '中1、3' },
    { label: '高1、3' },
    */
  ]
  return <Presenter {...{ grade, items, setGrade }} />
}

type PresenterProps = {
  grade: Accessor<string[]>
  items: { label: string }[]
  setGrade: (value: string) => void
}

function Presenter({ grade, items, setGrade }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '> button:last-child': {
          marginRight: '24px'
        },
        columnGap: '10px',
        display: 'flex',
        marginRight: '-24px',
        overflowX: 'scroll'
      })
    },
    Element: {
      button: css({
        minWidth: 'fit-content',
        padding: '10px 20px',
        ...shape('1px solid #ddd', undefined, '30px', undefined),
        ...font('#222', '14px', 400, '18px')
      })
    },
    Modifier: {
      on: css({
        backgroundColor: 'rgb(34, 34, 34)',
        borderColor: 'rgb(34, 34, 34)',
        ...font('#fff', '14px', 400, '18px')
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Group heading='受け入れ可能な学年'>
      <Div class={Block.container}>
        <For each={items}>
          {({ label }) => (
            <Button
              class={Element.button}
              classList={{ [Modifier.on]: grade().includes(label) }}
              onClick={() => setGrade(label)}
            >
              <Span>{label}</Span>
            </Button>
          )}
        </For>
      </Div>
    </Group>
  )
}
