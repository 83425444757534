import { css } from 'goober'
import { BsCheck } from 'solid-icons/bs'
import { useContext, For } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Group } from '~/components/root/filter'
import { App } from '~/contexts/App'
import { size, font, shape } from '~/utils/css-shorthand'

export function Course() {
  const { course, setCourse } = useContext(App)
  const items = [
    { description: '語学力の基礎を身につける、異文化への理解を深めることが出来る', label: '子供英語' }, // 子英
    { description: '受験対策は小学3年冬、小学4年頃から始めるのが一般的', label: '中学受験' }, // 中受
    { description: '早期入塾で内申点対策が可能、難関高校や推薦入試が目指せるようになる', label: '高校受験' }, // 高受
    {
      description: '専用の対策が欠かせないが、6年を通じたきめ細やかな教育が費用を抑え受けられる',
      label: '公立中高一貫校'
    }, // 公立一貫
    { description: '大学受験を意識するなら高2からの通塾が適切、高1からの通い始めも少なくない', label: '大学受験' }, // 大受
    { description: '医学部受験に特化しているため、必要な学習や対策がより効率よく行える', label: '医学部受験' }, // 医学部
    {
      description: '自ら主体的に学習する力を育むことで、学習の習慣が身に付いたり学習以外にも役立てられる',
      label: '自立型学習'
    }, // 自立型
    {
      description: 'Scratch や Minecraft を用いた学習カリキュラムが充実、やり抜く力（GRIT）を育む',
      label: 'プログラミング・ロボット'
    },
    { description: 'ローコスト・ハイクオリティで、自分のペースでその日の授業を進められる', label: '映像授業' }, // 映像
    {
      description: '情報の考察力とそこからの行動力が身につく、実験を通して将来役に立つ力を身につける',
      label: '理科実験'
    }
    // { description: '', label: '-' },
  ]
  return <Presenter {...{ course, items, setCourse }} />
}

type PresenterProps = {
  course: Accessor<string[]>
  items: { description: string, label: string }[]
  setCourse: (value: string) => void
}

function Presenter({ course, items, setCourse }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        display: 'grid',
        rowGap: '24px'
      })
    },
    Element: {
      button: css({
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr'
      }),
      checkbox: css({
        display: 'grid',
        marginInlineStart: '16px',
        placeContent: 'center',
        ...size('24px', '24px'),
        ...shape('1px solid rgb(176, 176, 176)', '#fff', '4px', undefined)
      }),
      description: css({
        ...font('#717171', '14px', 400, '18px'),
        marginTop: '4px'
      }),
      label: css({
        ...font('#222', '16px', 400, '20px')
      })
    },
    Modifier: {
      on: css({
        backgroundColor: 'rgb(34, 34, 34)',
        borderColor: 'rgb(34, 34, 34)'
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Group heading='特別コースから探す'>
      <Div class={Block.container}>
        <For each={items}>
          {({ label, description }) => (
            <Button class={Element.button} onClick={() => setCourse(label)}>
              <Div class={css({ display: 'grid' })}>
                <Span class={Element.label}>{label}</Span>
                <Span class={Element.description}>{description}</Span>
              </Div>
              <Span class={Element.checkbox} classList={{ [Modifier.on]: course()?.includes(label) }}>
                <BsCheck color='#fff' size={16} />
              </Span>
            </Button>
          )}
        </For>
      </Div>
    </Group>
  )
}
