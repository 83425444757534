import { splitProps } from 'solid-js'

type Props = ComponentProps<'h3'>

export function H3(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <h3 {...attributes}>{component.children}</h3>
}
