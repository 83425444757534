import { css } from 'goober'
import { createEffect, createSignal, useContext } from 'solid-js'
import { Div, Button } from '~/components/html-living-standard'
import { App } from '~/contexts/App'
import { font } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
}

export function Footer({ setShow }: Props) {
  const { data, type, setType, setRadius, grade, setGrade, course, setCourse, feature, setFeature } = useContext(App)
  const [size, setSize] = createSignal(0)
  createEffect(() => setSize(data()?.data.length ?? 0))

  const clear = () => {
    setRadius(1)
    type().forEach(v => setType(v))
    grade().forEach(v => setGrade(v))
    course().forEach(v => setCourse(v))
    feature().forEach(v => setFeature(v))
  }

  return <Presenter {...{ clear, setShow, size }} />
}

type PresenterProps = Props & {
  clear: () => void
  size: Accessor<number>
}

function Presenter({ clear, size, setShow }: PresenterProps) {
  return (
    <Div
      class={css({
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid rgba(0, 0, 0, 0.05)',
        bottom: '12px',
        display: 'flex',
        height: '71px',
        justifyContent: 'space-between',
        padding: '12px 24px',
        position: 'absolute',
        width: '100%'
      })}
    >
      <Button
        class={css({
          textDecoration: 'underline',
          ...font('#222', '16px', 600, undefined)
        })}
        onClick={clear}
      >
        すべてクリア
      </Button>
      <Button
        class={css({
          backgroundColor: '#222',
          borderRadius: '8px',
          display: 'grid',
          padding: '14px 24px',
          placeContent: 'center',
          ...font('#fff', '16px', 600, undefined)
        })}
        onClick={() => setShow(false)}
      >
        {size()}件の教室を表示
      </Button>
    </Div>
  )
}
