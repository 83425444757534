import { splitProps } from 'solid-js'

type Props = ComponentProps<'form'>

export function Form(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <form onSubmit={event => event.preventDefault()} {...attributes}>{component.children}</form>
}
