import { css } from 'goober'
import { RiMapMapPinLine } from 'solid-icons/ri'
import { useContext } from 'solid-js'
import { Button, Span } from '~/components/html-living-standard'
import { App } from '~/contexts/App'

type Props = import('~/components/root/address/Form').Data['features'][0] & {
  setShow: Setter<boolean>
  setShow2: Setter<boolean>
}

export function Candidate(props: Props) {
  const { map } = useContext(App)
  const fly = () => {
    props.setShow2(false)
    props.setShow(false)
    map()?.flyTo({ center: props.center })
  }
  return <Presenter fly={fly} {...props} />
}

type PresenterProps = Props & {
  fly: (place_name: string) => void
}

function Presenter({ place_name, fly }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        alignItems: 'center',
        columnGap: '16px',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto 1fr',
        padding: '8px 0',
        width: '100%'
      })
    }
  }
  const { Block } = Style
  return (
    <Button
      aria-label={`fly to ${place_name.split(',').slice(0, 2).join('')}`}
      class={Block.container}
      onClick={() => fly(place_name)}
    >
      <RiMapMapPinLine />
      <Span>{place_name.split(',').slice(0, 2).join('')}</Span>
    </Button>
  )
}
