import { createReducer } from '@solid-primitives/memo'
import { createLocalStorage } from '@solid-primitives/storage'
import { hc } from 'hono/client'
import { createSignal, createMemo, createResource, createEffect, Suspense, For, Show } from 'solid-js'
import { isServer } from 'solid-js/web'
import { Spinner } from '~/components/general'
import { Title, Meta } from '~/components/html-living-standard'
import { Drawer as FavoriteDrawer } from '~/components/root/favorite'
import { Drawer as ListDrawer } from '~/components/root/list'
import { Here, Map, Scope, Point, Pin, Snippet } from '~/components/root/map'
import { Bar, Search, Location, Filter, Feature } from '~/components/root/navigation'
import { Drawer as TutorialDrawer } from '~/components/root/tutorial'
import { App } from '~/contexts/App'
import { createDispatcher } from '~/utils/create-dispatcher'

export default function Page() {
  const dispatcher = createDispatcher()
  const [lonlat, setLonLat] = createSignal<LonLat>([undefined, undefined])
  const [radius, setRadius] = createSignal<Radius>(1)
  const [course, setCourse] = createReducer(dispatcher, [])
  const [feature, setFeature] = createReducer(dispatcher, [])
  const [grade, setGrade] = createReducer(dispatcher, [])
  const [type, setType] = createReducer(dispatcher, [])
  const [name, setName] = createSignal<string>()
  const params = createMemo(() => ({
    course: course().length ? course() : undefined,
    feature: feature().length ? feature() : undefined,
    grade: grade().length ? grade() : undefined,
    lat: lonlat()[1],
    lon: lonlat()[0],
    name: name()?.length ? name() : undefined,
    radius: radius(),
    type: type().length ? type() : undefined
  }))
  const [snippet, setSnippet] = createSignal<DataModel>()
  const [data] = createResource(params, async ({ name, lon, lat, radius, course, feature, grade, type }) => {
    if (!isServer) {
      const elements = document.querySelectorAll('.mapboxgl-canvas-container .mapboxgl-marker:not(:nth-of-type(1))')
      for (const element of elements) element.remove()
    }
    const api = hc<APIRoutes>(import.meta.env.VITE_SCHNAVI_API_ROOT_URL)
    const res = await api.rooms.search.$post({ json: { course, feature, grade, lat, lon, name, radius, type } })
    const data = await res.json()
    setLoading(false)
    setSnippet(undefined)
    return data
  })
  const [loading, setLoading] = createSignal(data.loading)
  const [center, setCenter] = createSignal<Center>([data()?.lon as number, data()?.lat as number])
  createEffect(() => setLonLat([center()[0], center()[1]]))

  const [address, setAddress] = createSignal(data()?.address)
  createEffect(() => setAddress(data()?.address as string))

  const [map, setMap] = createSignal<MapboxMap>()
  // favorite
  const [favoriteStore, setFavoriteStore] = createLocalStorage()
  const [favorite, setFavorite] = createReducer(dispatcher, JSON.parse(favoriteStore.data) as string[] ?? [])
  createEffect(() => {
    const data = favorite()
    setFavoriteStore('data', JSON.stringify(data)) // sync
  })
  const [showFavorite, setShowFavorite] = createSignal(false)
  // tutorial
  const [showTutorial, setShowTutorial] = createSignal(false)
  createEffect(() => {
    if (map()) {
      setTimeout(() => {
        setShowTutorial(true)
      }, 0)
    }
  })

  return (
    <>
      <Title>{`近くの教室を簡単検索 - ${import.meta.env.VITE_SERVICE_NAME}`}</Title>
      <Meta content={`近くの教室を簡単検索 - ${import.meta.env.VITE_SERVICE_NAME}`} name='description' use='solid' />
      <App.Provider
        value={{
          address,
          course,
          data,
          favorite,
          feature,
          grade,
          map,
          name,
          radius,
          setCourse,
          setFavorite,
          setFeature,
          setGrade,
          setName,
          setRadius,
          setShowFavorite,
          setType,
          showFavorite,
          type
        }}
      >
        {/* Navigation */}
        <Bar>
          <Search>
            <Location {...{ map }} />
            <Filter />
          </Search>
          <Feature />
        </Bar>
        {/* General */}
        <Show when={loading() || data.loading}>
          <Spinner />
        </Show>
        {/* Map */}
        <Map {...{ center, setCenter, setLoading, setMap, setSnippet }} />
        <Scope {...{ center, map, radius }} />
        <Point {...{ center, map }} />
        <Here {...{ map, setLoading }} />
        <Suspense>
          <For each={data()?.data}>
            {data => <Pin {...{ data, map, setSnippet, snippet }} />}
          </For>
        </Suspense>
        <Show when={snippet()}>
          <Suspense>
            <Snippet {...{ setSnippet, snippet }} />
          </Suspense>
        </Show>
        {/* List */}
        <Suspense>
          <ListDrawer {...{ data, loading }} />
        </Suspense>
        {/* Fovorite */}
        <FavoriteDrawer
          favorite={favorite}
          setShow={setShowFavorite}
          show={showFavorite}
        />
        {/* Tutorial */}
        <TutorialDrawer center={center} setShow={setShowTutorial} show={showTutorial} />
      </App.Provider>
    </>
  )
}
