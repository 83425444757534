import { css } from 'goober'
import { RiSystemCloseLine } from 'solid-icons/ri'
import { Smartphone } from '~/components/general/Smartphone'
import { Div, Button } from '~/components/html-living-standard'
import { display, position, font, size } from '~/utils/css-shorthand'

type Props = {
  center: Accessor<Center>
  setShow: Setter<boolean>
}

export function Header(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ setShow, center }: Props) {
  const Style = {
    Block: {
      container: css({
        ...display.grid.xy.center,
        ...position('sticky', 0, undefined, undefined, undefined, undefined),
        // backgroundColor: '#fff',
        // borderBottom: '1px solid #fff',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px'
        // height: '48px'
      })
    },
    Element: {
      button: css({
        ...position('absolute', '16px', undefined, undefined, '24px', undefined),
        display: 'flex'
      }),
      heading: css({
        ...font('#222', '16px', 600, '20px')
      })
    }
  }
  const { Block, Element } = Style

  // https://docs.mapbox.com/playground/static/
  const src = `https://api.mapbox.com/styles/v1/zerokonf/clahnygxk000c14qflhilp63g/static/pin-l+343434(${
    center().join(',')
  })/${center().join(',')},14,0/388x388@2x?access_token=${import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}`

  return (
    <Div class={Block.container}>
      <Div
        class={css({
          backgroundColor: '#eee',
          display: 'flex',
          overflow: 'hidden',
          ...size('100vw', '276px')
        })}
      >
        <Smartphone scale={0.75} src={src} top={0} width={321} y={-100} />
      </Div>
      <Button aria-label='close' class={Element.button} onClick={() => setShow(false)}>
        <RiSystemCloseLine />
      </Button>
    </Div>
  )
}
