import { css } from 'goober'
import { RiSystemSearchLine } from 'solid-icons/ri'
import { useContext, createSignal } from 'solid-js'
import { Button, Div, Span } from '~/components/html-living-standard'
import { Drawer } from '~/components/root/location'
import { App } from '~/contexts/App'
import { clamp, font } from '~/utils/css-shorthand'

type Props = {
  map: Accessor<MapboxMap | undefined>
}

export function Location(props: Props) {
  const { address } = useContext(App)
  return <Presenter address={address} {...props} />
}

type PresenterProps = Props & {
  address: Accessor<string>
}

function Presenter({ map, address }: PresenterProps) {
  const [show, setShow] = createSignal(false)
  const Style = {
    Block: {
      container: css({
        display: 'flex',
        width: 'calc(100% - 56px)'
      }),
      left: css({
        alignItems: 'center',
        display: 'flex',
        height: '54px',
        padding: '0 16px 0 20px'
      }),
      right: css({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        width: 'calc(100vw - 24px - 24px - 52px - 56px)'
      })
    },
    Element: {
      conditions: css({
        ...clamp(1, 16),
        ...font('#717171', '12px', 400, '16px')
      }),
      heading: css({
        ...font('#222', '14px', 600, '20px'),
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      })
    }
  }
  const { Block, Element } = Style
  return (
    <>
      <Button class={Block.container} onClick={() => setShow(!show())}>
        <Div class={Block.left}>
          <RiSystemSearchLine color='#222' size={16} />
        </Div>
        <Div class={Block.right}>
          <Span class={Element.heading}>現在地以外から探す</Span>
          <Span class={Element.conditions}>{address()}</Span>
        </Div>
      </Button>
      <Drawer {...{ map, setShow, show }} />
    </>
  )
}
