import { css } from 'goober'
import { useContext, For } from 'solid-js'
import { Div, Button, Span } from '~/components/html-living-standard'
import { Group } from '~/components/root/filter/Group'
import { App } from '~/contexts/App'
import { shape, font } from '~/utils/css-shorthand'

export function Radius() {
  const { radius, setRadius } = useContext(App)
  const items = [
    { label: '1km圏内' },
    { label: '2km圏内' },
    { label: '3km圏内' },
    { label: '4km圏内' }
  ]
  return <Presenter {...{ items, radius, setRadius }} />
}

type PresenterProps = {
  items: { label: string }[]
  radius: Accessor<Radius>
  setRadius: Setter<Radius>
}

function Presenter({ radius, setRadius, items }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '> button:last-child': {
          marginRight: '24px'
        },
        columnGap: '10px',
        display: 'flex',
        marginRight: '-24px',
        overflowX: 'scroll'
      })
    },
    Element: {
      button: css({
        minWidth: 'fit-content',
        padding: '10px 20px',
        ...shape('1px solid #ddd', undefined, '30px', undefined),
        ...font('#222', '14px', 400, '18px')
      })
    },
    Modifier: {
      on: css({
        backgroundColor: 'rgb(34, 34, 34)',
        borderColor: 'rgb(34, 34, 34)',
        ...font('#fff', '14px', 400, '18px')
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <Group heading='通学距離'>
      <Div class={Block.container}>
        <For each={items}>
          {({ label }, index) => (
            <Button
              class={Element.button}
              classList={{ [Modifier.on]: radius() === index() + 1 }}
              onClick={() => setRadius(index() + 1)}
            >
              <Span>{label}</Span>
            </Button>
          )}
        </For>
      </Div>
    </Group>
  )
}
