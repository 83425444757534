import { css } from 'goober'
import { Div } from '~/components/html-living-standard'
import { merge } from '~/utils/css-shorthand'
import 'devices.css'

type Props = ComponentProps<'div'> & { scale: number, src: string, top: number, width: number, y: number }

export function Smartphone(props: Props) {
  return <Presenter {...props} />
}

type PresenterProps = Props

function Presenter({ src, y, width, scale, top }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '.device-frame .device-screen': {
          backgroundColor: '#fff'
        },
        '.device-screen img': {
          borderRadius: 'inherit'
        },
        left: `${((scale - 1) * 428) / 2}px`,
        top: `${top}px`,
        transform: `scale(${scale}) translateY(${y}px) !important`
      }),
      outer: css({
        margin: '0 auto',
        width: `${width}px`
      })
    }
  }
  const { Block } = Style

  return (
    <Div class={merge([Block.outer])}>
      <Div class={merge(['device', 'device-iphone-14-pro', Block.container])}>
        <Div class='device-frame'>
          <Div class='device-screen'>
            <img alt='' class={css({ width: '100%' })} loading='lazy' src={src} />
          </Div>
        </Div>
        <Div class='device-stripe' />
        {
          /*
        <Div class='device-header' />
        <Div class='device-sensors' />
      */
        }
        <Div class='device-btns' />
        <Div class='device-power' />
        <Div class='device-home' />
      </Div>
    </Div>
  )
}
