import { css } from 'goober'
import { Svg, Circle, Animate, Span } from '~/components/html-living-standard'
import { position, display, size, shape } from '~/utils/css-shorthand'

export function Spinner() {
  return <Presenter />
}

function Presenter() {
  const Style = {
    Block: {
      container: css({
        pointerEvents: 'none',
        transform: 'translateX(-50%)',
        ...display.grid.xy.center,
        ...position('fixed', '160px', undefined, undefined, '50%', 3),
        ...size('72px', '40px'),
        ...shape(undefined, '#fff', '48px', '0 6px 16px rgba(0,0,0,0.12)')
      })
    }
  }
  const { Block } = Style

  return (
    <Span class={Block.container}>
      <Svg fill='#222' height='7.5' viewBox='0 0 120 30' width='30' xmlns='http://www.w3.org/2000/svg'>
        <Circle cx='15' cy='15' r='15'>
          <Animate
            attributeName='r'
            begin='0s'
            calcMode='linear'
            dur='0.8s'
            from='15'
            repeatCount='indefinite'
            to='15'
            values='15;9;15'
          />
          <Animate
            attributeName='fill-opacity'
            begin='0s'
            calcMode='linear'
            dur='0.8s'
            from='1'
            repeatCount='indefinite'
            to='1'
            values='1;.5;1'
          />
        </Circle>
        <Circle cx='60' cy='15' fill-opacity='0.3' r='9'>
          <Animate
            attributeName='r'
            begin='0s'
            calcMode='linear'
            dur='0.8s'
            from='9'
            repeatCount='indefinite'
            to='9'
            values='9;15;9'
          />
          <Animate
            attributeName='fill-opacity'
            begin='0s'
            calcMode='linear'
            dur='0.8s'
            from='0.5'
            repeatCount='indefinite'
            to='0.5'
            values='.5;1;.5'
          />
        </Circle>
        <Circle cx='105' cy='15' r='15'>
          <Animate
            attributeName='r'
            begin='0s'
            calcMode='linear'
            dur='0.8s'
            from='15'
            repeatCount='indefinite'
            to='15'
            values='15;9;15'
          />
          <Animate
            attributeName='fill-opacity'
            begin='0s'
            calcMode='linear'
            dur='0.8s'
            from='1'
            repeatCount='indefinite'
            to='1'
            values='1;.5;1'
          />
        </Circle>
      </Svg>
    </Span>
  )
}
