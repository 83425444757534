import { splitProps } from 'solid-js'

type Props = ComponentProps<'input'>

export function Input(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [, attributes] = splitProps(props, ['children'])
  return <input {...attributes} />
}
