import { splitProps } from 'solid-js'

type Props = ComponentProps<'animate'>

export function Animate(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <animate {...attributes}>{component.children}</animate>
}
