import { css } from 'goober'
import { Div, Article } from '~/components/html-living-standard'
import { Header, Type, Grade, Radius, Course, Feature, Footer } from '~/components/root/filter'
import { position, size } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
  show: Accessor<boolean>
}

export function Drawer(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ setShow, show }: Props) {
  const Style = {
    Block: {
      container: css({
        backgroundColor: '#fff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        marginTop: '12px',
        ...position('fixed', 0, undefined, undefined, undefined, undefined),
        ...size('100%', '100dvh'),
        '& div > section:last-child::after': {
          opacity: 0
        }
      }),
      layer: css({
        backgroundColor: 'rgb(34, 34, 34)',
        height: '100dvh'
      }),
      screen: css({
        width: '100%',
        ...position('fixed', 0, undefined, undefined, 0, 2)
      })
    },
    Modifier: {
      close: css({
        transform: 'translateY(100%)',
        transition: 'transform 400ms'
      }),
      hide: css({
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 400ms'
      }),
      off: css({
        pointerEvents: 'none'
      }),
      on: css({
        pointerEvents: 'auto'
      }),
      open: css({
        transform: 'translateY(0)',
        transition: 'transform 400ms'
      }),
      show: css({
        opacity: 0.6,
        pointerEvents: 'auto',
        transition: 'opacity 400ms'
      })
    }
  }
  const { Block, Modifier } = Style
  return (
    <Div class={Block.screen} classList={{ [Modifier.on]: show(), [Modifier.off]: !show() }}>
      <Div class={Block.layer} classList={{ [Modifier.show]: show(), [Modifier.hide]: !show() }} />
      <Article class={Block.container} classList={{ [Modifier.open]: show(), [Modifier.close]: !show() }}>
        <Header {...{ setShow }} />
        <Div class={css({ height: 'calc(100dvh - 60px - 71px)', overflowY: 'scroll' })}>
          <Div class={css({ height: '100%', paddingBlock: '24px' })}>
            <Type />
            <Radius />
            <Grade />
            <Course />
            <Feature />
          </Div>
        </Div>
        <Footer {...{ setShow }} />
      </Article>
    </Div>
  )
}
