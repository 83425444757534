import { css } from 'goober'
import type { KeenSliderInstance, KeenSliderHooks } from 'keen-slider'
import { Suspense, Show } from 'solid-js'
import { Header as HTMLHeader, Button, Span, H1 } from '~/components/html-living-standard'
import { display, position, size, shape, merge } from '~/utils/css-shorthand'

type Props = {
  data: Resource<{ data: DataModel[] }>
  loading: Accessor<boolean>
  // eslint-disable-next-line @typescript-eslint/ban-types
  slider: Accessor<KeenSliderInstance<{}, {}, KeenSliderHooks> | undefined>
}

export function Header(props: Props) {
  return <Presenter {...props} />
}

function Presenter({ data, slider, loading }: Props) {
  const Style = {
    Block: {
      container: css({
        ...display.grid.xy.center,
        '&::before': {
          content: '""',
          transform: 'translateX(-50%)',
          ...position('absolute', '8px', undefined, undefined, '50%', undefined),
          ...size('40px', '4px'),
          ...shape(undefined, 'rgba(32, 32, 32, 0.2)', '4px', undefined)
        },
        padding: '32px 24px 0'
      })
    },
    Element: {
      button: css({
        position: 'absolute',
        ...size('100%', '100%')
      }),
      heading: css({
        color: '#222',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px'
      }),
      skeleton: css({
        ...size('256px', '18px'),
        ...shape(undefined, '#222', '4px', undefined)
      })
    },
    Modifier: {
      skeleton: css({
        '@keyframes skeleton': {
          from: { opacity: 0.09049773755656108 },
          to: { opacity: 0.15384615384615385 }
        },
        animation: 'skeleton 1s ease-in-out infinite'
      })
    }
  }
  const { Block, Element, Modifier } = Style
  return (
    <HTMLHeader class={merge(['keen-slider__slide', Block.container])}>
      <Button aria-label='open' class={Element.button} onClick={() => slider()?.next()} />
      <Show when={data.loading || loading()}>
        <Span class={merge([Element.skeleton, Modifier.skeleton])} />
      </Show>
      <Suspense>
        <Show when={!data.loading && (data()?.data ?? []).length}>
          <H1 class={Element.heading}>{(data()?.data ?? []).length}件の近くの教室</H1>
        </Show>
        <Show when={!data.loading && !(data()?.data ?? []).length}>
          <H1 class={Element.heading}>完全に一致する検索結果がありません</H1>
        </Show>
      </Suspense>
    </HTMLHeader>
  )
}
