import createCircle from '@turf/circle'
import { createUniqueId, createEffect, createMemo } from 'solid-js'

type Props = {
  center: Accessor<Center>
  map: Accessor<MapboxMap | undefined>
  radius: Accessor<Radius>
}

export function Scope({ center, radius, map }: Props) {
  const id = createUniqueId()
  createEffect(() => {
    if (!map()) return
    const data = createMemo(() =>
      createCircle(
        center(),
        radius() ?? 1,
        { steps: 100, units: 'kilometers' }
      )
    )
    const source = map()?.getSource(id) as GeoJSONSource | undefined
    typeof source === 'undefined'
      ? map()?.addLayer({
        id,
        paint: { 'fill-color': 'rgb(0, 157, 224)', 'fill-opacity': 0.1 },
        source: { data: data(), type: 'geojson' },
        type: 'fill'
      })
      : source.setData(data())
  })
  return <Presenter />
}

function Presenter() {
  return <></>
}
