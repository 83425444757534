import { css } from 'goober'
import { useContext } from 'solid-js'
import { Div, Button } from '~/components/html-living-standard'
import { App } from '~/contexts/App'
import { font } from '~/utils/css-shorthand'

type Props = {
  setShow: Setter<boolean>
}

export function Footer({ setShow }: Props) {
  const { favorite, setFavorite } = useContext(App)
  const clear = () => {
    favorite().forEach(favorite => {
      setFavorite(favorite)
    })
    setShow(false)
  }
  return <Presenter clear={clear} />
}

type PresenterProps = {
  clear: () => void
}

function Presenter({ clear }: PresenterProps) {
  return (
    <Div
      class={css({
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid rgba(0, 0, 0, 0.05)',
        bottom: '12px',
        display: 'grid',
        height: '71px',
        padding: '12px 24px',
        position: 'absolute',
        width: '100%'
      })}
    >
      <Button
        class={css({
          textDecoration: 'underline',
          ...font('#222', '16px', 600, undefined)
        })}
        onClick={clear}
      >
        すべてクリア
      </Button>
    </Div>
  )
}
