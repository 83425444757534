import { css } from 'goober'
import { createSignal, createResource, For, Suspense, createEffect } from 'solid-js'
import { Form as HTMLForm, Input, Div } from '~/components/html-living-standard'
import { Candidate } from '~/components/root/address'
import { size, shape, font } from '~/utils/css-shorthand'

export type Data = {
  features: {
    center: Center
    place_name: string
  }[]
}

type Props = {
  setShow: Setter<boolean>
  setShow2: Setter<boolean>
  show2: Accessor<boolean>
}

export function Form({ show2, setShow, setShow2 }: Props) {
  const [query, setQuery] = createSignal('')

  let ref!: HTMLInputElement

  createEffect(() => {
    if (!show2()) return
    setTimeout(() => {
      ref.focus()
    }, 400) // animation
  })

  const onInput = ({ target }: Event) => {
    const value = (target as HTMLInputElement).value
    if (value.trim().length <= 1) return
    setQuery(value)
  }
  const fetcher = async (query: string) => {
    const url = new URL(`${import.meta.env.VITE_MAPBOX_PLACES_API_URL}/${query}.json`)
    url.search = new URLSearchParams({
      access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
      country: 'JP',
      language: 'ja'
    }) as unknown as string
    const res = await fetch(url)
    const data: Data = await res.json()
    return data
  }
  const [candidates] = createResource(query, fetcher)
  return <Presenter ref={ref} setShow={setShow} setShow2={setShow2} show2={show2} {...{ candidates, onInput }} />
}

type PresenterProps = Props & {
  candidates: Resource<Data>
  onInput: (event: Event) => void
  ref: HTMLInputElement
}

function Presenter({ ref, setShow, setShow2, candidates, onInput }: PresenterProps) {
  const Style = {
    Element: {
      input: css({
        marginTop: '16px',
        padding: '0 20px',
        ...size('100%', '60px'),
        ...font('#222', '16px', 400, '20px'),
        ...shape(undefined, '#f7f7f7', '12px', undefined)
      })
    }
  }
  const { Element } = Style
  return (
    <>
      <HTMLForm>
        <Input class={Element.input} onInput={onInput} placeholder='住所・ランドマークから検索' ref={ref} type='text' />
      </HTMLForm>
      <Suspense>
        <Div class={css({ marginBlockStart: '16px' })}>
          <For each={candidates()?.features}>
            {props => (
              <Candidate setShow={setShow} setShow2={setShow2} {...props} />
            )}
          </For>
        </Div>
      </Suspense>
    </>
  )
}
